import { EventEmitter, inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Event, NavigationEnd, NavigationStart, Params, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  history: string[] = [];
  historyParams: Params[] = [];
  #router = inject(Router);
  #location = inject(Location);
  #currentUrl: string;
  #navigatedUrl: string;
  #navigationEnd$ = this.#router.events.pipe(
    filter((e: Event) => e instanceof NavigationEnd),
  ) as Observable<NavigationEnd>;
  #navigationStart$ = this.#router.events.pipe(
    filter((e: Event) => e instanceof NavigationStart),
  ) as Observable<NavigationStart>;

  updateDetailsActionEmitter = new EventEmitter<boolean>();

  constructor() {
    this.#navigationEnd$.subscribe((event) => {
      this.history.push(event.urlAfterRedirects);
      this.setNavigatedUrl(event.url.split('?')[0]);
      const currentParts = this.#currentUrl?.split('/') ?? [];
      if (currentParts[currentParts.length - 1] === 'new') {
        const urlParts = this.#navigatedUrl.split('/');
        urlParts.pop();
        currentParts.pop();
        if (urlParts.join('/') === currentParts.join('/')) {
          this.#currentUrl = this.#navigatedUrl;
          return;
        }
      }
      if (this.#navigatedUrl !== this.#currentUrl) {
        this.#currentUrl = this.#navigatedUrl;
        this.updateDetailsActionEmitter.emit(true);
      }
    });

    this.#navigationStart$.subscribe((event) => {
      const params = event.url.split('?')[1];
      if (params) {
        const queryParams = this.createQueryParams(params);
        this.historyParams.push(queryParams);
      }
    });
  }

  createQueryParams(queryString: string): HttpParams {
    let params = new HttpParams();

    const queryPairs = queryString.split('&');
    queryPairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      params = params.set(key, decodeURIComponent(value));
    });

    return params;
  }

  getPreviousParams(): Params {
    return this.historyParams[this.historyParams.length - 1];
  }

  setNavigatedUrl(url: string) {
    this.#navigatedUrl = url;
  }

  setCurrentUrl(url: string) {
    this.#currentUrl = url;
  }

  getNavigatedUrl() {
    return this.#navigatedUrl;
  }

  getCurrentUrl() {
    return this.#navigatedUrl;
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.#location.back();
    } else {
      this.#router.navigateByUrl('/');
    }
  }

  getLastQueryParams() {
    const returnParams: Record<string, string | string[]> = {};
    const params: Record<string, string>[] = this.historyParams[this.historyParams.length - 1]
      ? this.historyParams[this.historyParams.length - 1]['updates']
      : null;
    params?.forEach((update: any) => {
      returnParams[update.param] = update.value;
    });
    return returnParams;
  }
}
